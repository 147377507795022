p.notfound {
    margin: 0 1rem;
}

.notfoundbox {
    display: flex;
    align-content: center;
    justify-content: center;
}

.sadsham {
    height: 200px;
}

h1.hero {
	font-size: 5em;
	font-family: 'Special Elite', cursive; /* Like this one! */
	text-align: center;
    margin-top: 1.3rem;
}

h2.hero {
	margin-top: -26px;
	font-size: 2.2em;
	font-family: 'Special Elite', cursive;
	text-align: center;
}

.fourohfour {
	font-family: 'Bowlby One SC', cursive;
    font-size: 1.3rem;
    margin: 0 1.3rem;

    a {
        font-family: 'Bowlby One SC', cursive;
        font-size: 1.3rem;
        margin: 0 0.5rem;
    }
}