/* CODE DEALING WITH THE CHARACTER BIOS NAME LIST! */

.char-namelist {
	display: grid;
	grid-template-columns: 1fr 2fr 2fr 1fr;
	grid-auto-rows: minmax(50px auto);
	align-items: center;
	border-top: 2px dotted $darkRed;
	border-bottom: 2px dotted $darkRed;
}

.char-col-left {
	grid-column: 2/3;
	align-self: end;
	padding: 1em 0;
	border-right: 3px solid $lightRed;
}

.char-col-right {
	grid-column: 3/4;
	align-self: start;
	padding: 1em 0;
	border-left: 3px solid $lightRed;
}

p.char-name {
	font-size: 1em;
	font-family: map-get($fonts, funkStyle);
	text-align: right;
	text-transform: uppercase;
	height: 40px;
	vertical-align: middle;
	color: $darkRed;
	padding-right: 0.3em;
	// margin: 10px 0;
}

p.char-desc {
	font-size: 0.9em;
	font-family: map-get($fonts, primary);
	text-align: left;
	vertical-align: middle;
	height: 40px;
	color: black;
	padding: 0 0.4em;
}

p.tiny-text {
	font-size: 0.5em;
	font-family: map-get($fonts, primary);
}

/* CHARACTER BIO PAGE STYLING */

h3.article-head {
	font-size: 2em;
	font-family: map-get($fonts, headlines);
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 5px;
}

/* MOBILE CHARACTER LIST STYLING */

.mob-char-list {
	padding: 1em;
	border: 1px dotted $lightRed;
	text-align: center;
}
.mob-char-list {
	font-family: map-get($fonts, funkStyle);
	font-size: 0.7em;
	text-transform: uppercase;
}
